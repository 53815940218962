<template src="./faq.html" />

<script>
import { computed } from "vue"

import { useStore } from "@/store/store.js"
import mixins from "@/mixins/mixins.js"
import questions from "@/constants/faq"

export default {
  mixins: [mixins],
  metaInfo: {
    title: "Filóo - FAQ",
    htmlAttrs: {
      lang: "pt-BR",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "Veja as respostas para todas as suas dúvidas sobre a Filóo! Caso necessário, mande sua mensagem para nós que responderemos o mais rápido possível! Confira!"},
    ],
  },
  data() {
    return {
      busy: false,
      questionActive: null,
      name: null,
      email: null,
      cellphone: null,
      ddd: null,
      nomePergunta: "",
      apresenta: false,
      mergeDDDAndCell: null,
      note: null,
      invalidField: {
        name: false,
        email: false,
        cellphone: false,
        ddd: false,
        note: false,
      },
      alertMessage: {
        name: "",
        email: "",
        cellphone: "",
        ddd: "",
        note: "",
      },
    }
  },
  computed:{
    isDasa() {
      if (this.user) {
        const plans = ["dasa_mp_basic","dasa_mp_plus"]

        return plans.includes(this.user.customerPlan.code)
      }
      return false
    },
  },
  setup () {
    const store = useStore()

    return {
      user: computed(() => store.getters.user),
      questions,
    }
  },
  methods: {
    showQuestions(type) {
      const vm = this;
      if (type == "patient") {
        vm.questionActive = type;
        vm.apresenta = false;
        vm.nomePergunta = "";
      } else {
        vm.questionActive = null;
        vm.apresenta = true;
        if (type == "company") {
          vm.nomePergunta = "Empresa";
        } else {
          vm.nomePergunta = "Médico, clínica ou profissional da saúde";
        }
      }
    },
    validateFields() {
      const vm = this;
      vm.busy = true;
      const emptyFieldText = "Campo obrigatório";
      let allValid = true;
      if (vm.testNullUndefinedEmpty(vm.name)) {
        allValid = false;
        vm.invalidField.name = true;
        vm.alertMessage.name = emptyFieldText;
      }
      if (vm.testNullUndefinedEmpty(vm.note)) {
        allValid = false;
        vm.invalidField.note = true;
        vm.alertMessage.note = emptyFieldText;
      }
      if (vm.testNullUndefinedEmpty(vm.email)) {
        allValid = false;
        vm.invalidField.email = true;
        vm.alertMessage.email = emptyFieldText;
      } else if (!vm.validateEmail(vm.email)) {
        allValid = false;
        vm.invalidField.email = true;
        vm.alertMessage.email = "Insira um e-mail válido";
      }
      if (vm.testNullUndefinedEmpty(vm.ddd)) {
        allValid = false;
        vm.invalidField.ddd = true;
        vm.invalidField.cellphone = true;
        vm.alertMessage.cellphone = "O DDD é obrigatório";
      } else if (vm.ddd.length < 2) {
        allValid = false;
        vm.invalidField.ddd = true;
        vm.invalidField.cellphone = true;
        vm.alertMessage.cellphone = "Insira um DDD válido";
      }
      if (vm.testNullUndefinedEmpty(vm.cellphone)) {
        allValid = false;
        vm.invalidField.cellphone = true;
        vm.alertMessage.cellphone = emptyFieldText;
      } else {
        if (vm.cellphone.length < 11) {
          vm.invalidField.cellphone = true;
          vm.alertMessage.cellphone = "Digite um número válido";
        } else {
          const onlyNumberCell = vm.cellphone.replace(/[^\d]+/g, "");
          const onlyNumberDDD = vm.ddd.replace(/[^\d]+/g, "");
          vm.mergeDDDAndCell = onlyNumberDDD + onlyNumberCell;
          if (vm.mergeDDDAndCell.length < 11) {
            allValid = false;
            vm.invalidField.cellphone = true;
            vm.alertMessage.cellphone = "Insira um número válido";
          }
        }
      }
      if (allValid && !vm.invalidAccess) {
        vm.sendMessage();
      } else {
        vm.busy = false;
      }
    },
    sendMessage() {
      const vm = this;
      const data = {
        name: vm.name,
        email: vm.email,
        phone: vm.mergeDDDAndCell,
      };
      vm.axios
        .post(vm.$store.state.filooServer + "to define", data)
        .then(function (response) {
          if (response.status === 200) {
            vm.showNotifySuccess(
              "Operação realizada",
              "Sua mensagem foi enviada com sucesso.",
              6000
            );
          }
        })
        .catch(function (error) {
          vm.showNotifyError(
            "Não foi possível realizar a requisição e enviar a mensagem",
            6000
          );
          console.error({
            error: error,
            sent_object: data,
            endpoint: "to define",
            http_method: "POST",
          });
        })
        .finally(function () {
          setTimeout(() => {
            vm.busy = false;
          }, 600);
        });
    },
  },
};
</script>

<style lang="scss" src="./faq.scss" />
