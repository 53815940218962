export default [
  {
    title: "COMO USAR O CARTÃO PARA OBTER DESCONTOS EM SAÚDE?",
    description: [
      "Com sua conta ativa basta escolher o serviço de sua preferência e realizar o agendamento direto por aqui, ou se preferir por telefone através dos números presentes nos cartões com informações sobre os laboratórios e consultórios.",
    ],
  },
  {
    title: "PARA AGENDAMENTO DE EXAMES E VACINAS",
    description: [
      "Acesse o menu “Meus Serviços” e entre em “Laboratórios”, insira seu CEP ou ative a geolocalização e escolha o laboratório de sua preferência e ligue diretamente no número presente nos cartões dos laboratórios e fale com um de nossos atendentes que irão te auxiliar com suas necessidades (incluindo consulta de valores dos exames).",
      "No dia de seu agendamento, apresente seu cartão virtual disponível no menu Cartão Virtual para o consultor que realizar o seu entendimento na Unidade escolhida e siga com o pagamento do valor dos exames ou vacinas já com o desconto aplicado.",
    ],
  },
  {
    title: "PARA AGENDAMENTO DE CONSULTAS PRESENCIAIS",
    description: [
      "Acesse o menu “Nova Consulta”, insira os dados necessários de especialidade, período de preferência, cadastro pessoal e solicite a consulta com o profissional de sua preferência.",
    ],
  },
  {
    title: "INFORMAÇÕES SOBRE CONSULTAS PRESENCIAIS",
    description: [
      "Agendamento em consultórios – valor cobrado antecipadamente na plataforma. Observação importante: para utilizar consultas nessa modalidade será necessário realizar uma recarga de saldo na plataforma para que o pagamento seja realizado com antecedência a consulta. ",
      "Agendamento em clínicas (Hospitais Dasa) – valor cobrado presencialmente na unidade. No dia de seu agendamento, apresente seu cartão virtual disponível no menu “Cartão Virtual” para o consultor que realizar o seu entendimento na Unidade escolhida e siga com o pagamento do valor da consulta já com o desconto aplicado. Não é necessário realizar o pré-pagamento e recarga de saldo na plataforma. ",
    ],
  },
  {
    title: "AGENDAMENTO E INFORMAÇÕES SOBRE CONSULTAS ONLINE",
    description: [
      "Para agendar a consulta online você será direcionado para a Plataforma Nav da DASA, se não tiver cadastro, basta realizá-lo, ou acesse sua conta, e você já será direcionado para a página de agendamento.",
      "Para seguir com seu agendamento selecione como forma de pagamento a modalidade “Particular” com o valor já com seu desconto do plano ativo e siga com o pagamento de acordo com sua preferência.",
      "Depois basta acessar a plataforma NAV novamente no dia e horário de sua consulta agendada e realizar a sua consulta.",
    ],
  },
  {
    title: "PARA UTILIZAÇÃO DO PRONTO ATENDIMENTO DIGITAL",
    description: [
      "Acesse “Meus Serviços”, entre em “Pronto Atendimento Virtual” e confirme a mensagem.",
      "Para realizar o atendimento você será direcionado para a Plataforma Nav da DASA, se não tiver cadastro, basta realizá-lo, ou acesse sua conta, você será redirecionado para a página da consulta.",
      "Para seguir com seu atendimento com custo zero e ilimitado selecione como forma de pagamento “Cartão Dasa + Saúde”, aceite os termos e siga para a consulta.",
    ],
  },
  {
    title: "PARA UTILIZAÇÃO DOS DESCONTOS EM FARMÁCIA",
    description: [
      "Acesse o menu “Meus Serviços” e em seguida “Descontos em medicamentos” e visualize todas as lojas próximas a sua localização (as redes de farmácia disponíveis dependerão de sua localização – sempre utilizar a plataforma para visualizar as unidades integrantes do programa).",
      "Na Unidade escolhida, apresente seu cartão virtual.",
      "<i>Regras de desconto em medicamentos:</i>",
      "Para Drogaria São Paulo e Pacheco – 15 a 70% de desconto em medicamentos genéricos e 10 a 50% de desconto em medicamentos de marcas. <b><i>Carência para utilização de até 7 dias.</i></b>",
      "Para Pague Menos e Extrafarma – 30% de desconto em medicamentos genéricos e 17% de desconto em medicamentos de marca e similares. <b><i>Carência para utilização de até 7 dias.</i></b>",
    ],
  },
  {
    title: "PARA UTILIZAÇÃO DE PACOTES CIRÚRGICOS (APENAS PARA O PLANO “PLUS”)",
    description: [
      "Acesse o menu “Meus Serviços” e em seguida “Pacotes cirúrgicos” e visualize todos os procedimentos disponíveis em seu plano. Para mais detalhes e orçamento contate nosso Concierge através do número: (11) 93236-2260 (WhatsApp e chamadas).",
    ],
  },
  {
    title: "COMO POSSO VER A REDE HOSPITALAR E DIAGNÓSTICA CREDENCIADA NO CARTÃO?",
    description: [
      "Para acessar todas as unidades de diagnóstico e hospitalares basta acessar o menu “Nova Consulta” e em “Meus Serviços” a opção “Laboratórios” ou “Cirurgias” e você terá acesso de acordo com a sua localização a todas as unidades próximas de seu endereço.",
    ],
  },
  {
    title: "COMO ADICIONAR OS MEUS DEPENDENTES?",
    description: [
      "Para adicionar seus dependes/familiares basta acessar o menu “Minha Assinatura” e em seguida em “Meus Dependentes”. Clique em “Gerenciar beneficiários” e preencha as informações pessoais de cadastro.",
      "Importante: você pode adicionar até 4 dependentes sem custo adicional, e não será possível modificar o dependente até o fim do seu contrato anual. ",
      "Se tiver adicionado alguma informação incorreta, exclua o cadastro do dependente e preencha novamente. <b><i>Para exclusão contatar o time técnico através do número: (11) 97464-0527</i></b>. ",
    ],
  },
  {
    title: "QUAIS SERVIÇOS NÃO ESTÃO COBERTOS PELA MINHA ASSINATURA?",
    description: [
      "Não estão cobertos os pronto-atendimentos presenciais em nossas redes hospitalares e quaisquer procedimentos mesmo que de baixa complexidade durante as consultas médicas. Mas você poderá passar pelo nosso Pronto Atendimento Online, sem custo e quantas vezes quiser, acessando o menu “Meus Serviços” e “Pronto Atendimento Online”.",
    ],
  },
  {
    title: "COMO CANCELO MINHA ASSINATURA?",
    description: [
      "Sua assinatura terá vigência anual e o cancelamento poderá ser efetuado após os 12 meses de contratação. Para mais informações, contate o Termo de Uso do cartão assinado no momento de sua contratação ou nosso Concierge: (11) 93236-2260 (WhatsApp e chamadas).",
    ],
  },
]
